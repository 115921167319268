import * as firebase from 'firebase'
import 'firebase/firestore';
import 'firebase/auth';

let firebaseConfig = {
    apiKey: 'AIzaSyCeS_GQ1YteyDeAfrguIFAPH194WwFcRh4',
    authDomain: 'wishlist-sidko.firebaseapp.com',
    databaseURL: 'https://wishlist-sidko.firebaseio.com',
    projectId: 'wishlist-sidko',
    storageBucket: 'wishlist-sidko.appspot.com',
    messagingSenderId: '442775662001',
    appId: '1:442775662001:web:c70dd22b5bc5aa31ed6190',
    measurementId: 'G-7XGBY3161W'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
