import React from 'react'
import { WishItem } from './WishItem'
import styles from './WishList.module.css'

import { useObserver } from 'mobx-react-lite'
import { useStore } from '../mobx/stores/wishes-context'
import { useFirestoreFetcher } from '../mobx/stores/wishes-fetcher'
import { Wish, WishResponse, WishSnippet } from '../mobx/stores/wishes-store'

export const WishList = () => {
    const store = useStore()
    const result = useFirestoreFetcher<WishResponse>(`users`)

    return useObserver(() => {
        if (store.loading) {
            return <div>Loading...</div>
        }
        if (store.error) {
            return <div>Request Error: { store.error.message }</div>
        }

        const wishes = result?.wishes || {}
        const sortedKeys = Object.keys(wishes).sort((a, b) => {
            return wishes[b].t.seconds - wishes[a].t.seconds
        })

        return (
            <div className = { styles.wrapper }>
                { sortedKeys.map((wishId) =>
                    <WishItem
                        key = { wishId }
                        wishId = { wishId }
                        wish = { wishSnippetMap(wishes[wishId]) }
                    />
                ) }
            </div>
        )
    })
}

function wishSnippetMap(snippet: WishSnippet): Wish {
    return {
        name: snippet.n,
        price: snippet.p,
        currency: snippet.c,
        imageHash: snippet.i,
        description: snippet.d,
        timestamp: snippet.t,
        url: snippet.u,
    }
}
