import React from 'react'
import styles from './WishItem.module.css'
import { Wish } from '../mobx/stores/wishes-store'

import { useParams } from 'react-router-dom'

const currencyFormatter = new Intl.NumberFormat(
    window.navigator.language,
    { style: 'currency', currency: 'UAH', currencyDisplay: 'symbol' }
)

export const WishItem = (props: { wishId: string, wish: Wish }) => {
    let { userId } = useParams()
    const { wishId, wish } = props
    const imagesHost = 'https://storage.googleapis.com/wishlist-sidko.appspot.com/images'
    return <div id = { 'wish-' + wishId } className = { styles.wrapper }>
        <div className = { styles.left }>
            <div className = { styles.photo }>
                <img alt = { 'wish' } src = { `${ imagesHost }/${ userId }/${ wish.imageHash }/x192.jpg` }/>
            </div>
        </div>
        <div className = { styles.right }>
            <div className = { styles.name }>
                <a target="_blank" rel="noopener noreferrer" href={`https://shorty.sidko.name/${wish.url}`}>{ wish.name }</a>
            </div>
            <div className = { styles.description }>{ wish.description }…</div>
            <div className = { styles.bottom }>
                <div className = { styles.price }>{ currencyFormatter.format(wish.price) }</div>
            </div>
        </div>
    </div>
}
