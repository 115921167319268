import React, { useEffect } from 'react'
import * as firebase from 'firebase'
import * as firebaseui from 'firebaseui'

import { useLocalStore, useObserver } from 'mobx-react-lite' // 6.x

import styles from './AuthWindow.module.css'

export const AuthWindow = () => {
    const state = useLocalStore(() => ({
        isOpen: false,
        open() {
            state.isOpen = true
            setTimeout(() => {
                ui.start('#firebaseui-auth-container', uiConfig)
            }, 50)
        },
        onModalClick(e: React.MouseEvent<HTMLDivElement>) {
            let target = e.target as HTMLDivElement
            if (target.className === e.currentTarget.className) {
                state.isOpen = false
            }
        },
        onKeyUp(e: KeyboardEvent) {
            if (e.key === 'Escape') {
                state.isOpen = false
            }
        }
    }))

    useEffect(() => {
        window.addEventListener('keyup', state.onKeyUp)
        return () => window.removeEventListener('keyup', state.onKeyUp)
    })


    return useObserver(() => (
        <div className = { styles.wrapper }>
            <div className = { styles.icon } onClick = { state.open }>^_^</div>
            { state.isOpen && (
                <div className = { styles.modal } onClick = { state.onModalClick }>
                    <div className = { styles.auth } id = "firebaseui-auth-container"></div>
                    <div id = "loader">Loading...</div>
                </div>
            ) }
        </div>
    ))
}

const ui = new firebaseui.auth.AuthUI(firebase.auth())

const uiConfig: firebaseui.auth.Config = {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true
        },
        uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('loader')!.style.display = 'none'
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '<url-to-redirect-to-on-success>',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    tosUrl: '<your-tos-url>',
    // Privacy policy url.
    privacyPolicyUrl: '<your-privacy-policy-url>'
}
