import React from 'react'
import { reaction } from 'mobx'
import { useAsObservableSource } from 'mobx-react-lite' // 6.x

import { useStore } from './wishes-context'
import * as firebase from 'firebase'

type DocumentReference<T> = firebase.firestore.DocumentReference<T>

const db = firebase.firestore()

export function useFirestoreFetcher<T>(
    collection: string
): T | null {
    const store = useStore()
    const source = useAsObservableSource({ collection: `${ collection }` })
    const [result, setResult] = React.useState<any>(null)

    const doFetch = <T>(documentRef: DocumentReference<T> | null) => {
        store.setLoading(true)

        if (!documentRef) {
            return
        }

        documentRef.get()
            .then(json => {
                setResult(json.data())
            })
            .catch(err => {
                store.setError(err)
            })
            .finally(() => {
                store.setLoading(false)
            })
    }

    React.useEffect(
        () => reaction(() => {
            const docKey = (store as any)[source.collection + 'Id']
            if (!docKey) {
                return null
            }
            return db.collection(source.collection).doc(docKey)
        }, doFetch)
    )

    return result
}
