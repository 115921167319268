import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch, useParams
} from "react-router-dom";

import './App.css';
import { WishList } from './components/WishList';
import { useStore } from './mobx/stores/wishes-context'
import { AuthWindow } from './components/AuthWindow';

const App: React.FC = () => {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/user">
                        <User/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
                <AuthWindow />
            </div>
        </Router>
    );
}

function Home() {
    return <h2>Home</h2>;
}

function User() {
    let match = useRouteMatch();

    return (
        <div>
            <Route path={`${match.path}/:userId`}>
                <ListRoute/>
            </Route>
        </div>
    );
}

function ListRoute() {
    let {userId} = useParams();
    const store = useStore()
    setTimeout(()=>{
        store.usersId = userId!
    }, 1000)
    return <WishList />
}


export default App;
