import * as firebase from 'firebase'
type Timestamp = firebase.firestore.Timestamp

export interface Wish {
    name: string
    price: number
    currency: string
    imageHash: string
    description: string
    timestamp: Timestamp
    url: string
}

export interface WishSnippet {
    n: string
    p: number
    c: string
    i: string
    d: string
    t: Timestamp
    u: string
}

export interface WishStore {
    usersId: string
    loading: boolean
    error?: Error
    wishes: Wish[],
    setLoading: (loading: boolean) => void
    setError: (err: Error) => void
    cheapWishes: Wish[]
}

export type WishResponse = {
    wishes: Record<string, WishSnippet>
}

export function createStore() {
    return {
        usersId: '',
        loading: false,
        error: void 0,
        wishes: [] as Wish[],

        setLoading: function(loading) {
            this.loading = loading
        },
        setError: function(err) {
            this.error = err
        },

        get cheapWishes() {
            return this.wishes.filter(w => w.price < 100)
        },
    } as WishStore
}

export type TStore = ReturnType<typeof createStore>
