import * as firebase from 'firebase'
import React from 'react'
import ReactDOM from 'react-dom'
import './firebase'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { StoreProvider } from './mobx/stores/wishes-context'


firebase.auth().signInAnonymously()
    .then(() => {
        ReactDOM.render(
            <StoreProvider>
                <App/>
            </StoreProvider>,
            document.getElementById('root')
        )
    })
    .catch(function(error) {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message

        console.error(errorCode, errorMessage)
    })


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
